import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import nprogress from "nprogress";
import store from "@/store";

Vue.use(VueRouter);

export const menuList = [
  {
    path: "/desk",
    name: "desk",
    meta: {
      label: "球桌管理"
    },
    component: () => import("@/views/desk/index.vue")
  },
  {
    path: "/club",
    name: "club",
    meta: {
      label: "球杆管理"
    },
    component: () => import("@/views/club/index.vue")
  },
  {
    path: "/part",
    name: "part",
    meta: {
      label: "配件管理"
    },
    component: () => import("@/views/part/index.vue")
  },
  {
    path: "/about",
    name: "about",
    meta: {
      label: "企业简介"
    },
    component: () => import("@/views/about/index.vue")
  },
  {
    path: "/record",
    name: "record",
    meta: {
      label: "验真管理"
    },
    component: () => import("@/views/record/index.vue")
  },
  {
    path: "/case",
    name: "case",
    meta: {
      label: "案例管理"
    },
    component: () => import("@/views/case/index.vue")
  },
  {
    path: "/feed",
    name: "feed",
    meta: {
      label: "咨询管理"
    },
    component: () => import("@/views/feed/index.vue")
  }
];

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
    redirect: "/desk",
    children: menuList
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  nprogress.start();
  if (to.path === "/login") {
    next();
  } else {
    const current = store.getters["current"];
    if (current.isLogin) {
      next();
    } else {
      const res = (await store.dispatch("user/loadCurrent")) as any;
      if (res) {
        next();
      } else {
        next("/login");
      }
    }
  }
});

router.afterEach(() => {
  nprogress.done();
});

export default router;
