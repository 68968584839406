import { $http } from "@/request";

export default {
  namespaced: true,
  state: {
    current: {
      isLogin: false
    }
  },
  mutations: {
    setCurrent(state: any, current: any) {
      state.current = current;
    }
  },
  actions: {
    async loadCurrent({ commit }: any) {
      let result = true;
      try {
        const res = await $http({
          url: "/auth-api/current"
        });
        if (res.success) {
          commit("setCurrent", {
            ...res.data,
            isLogin: true
          });
        }
        result = res.success;
      } catch (error) {
        result = false;
      }

      return result;
    }
  }
};
