import axios from "axios";
import Vue from "vue";
import { MAxiosInstance } from "@/typings/vue-component.d";
import { Loading } from "element-ui";
import { ElLoadingComponent } from "element-ui/types/loading";

let loading: ElLoadingComponent | null = null;

function closeLoading() {
  loading?.close();
  loading = null;
}

const service = axios.create({
  baseURL: "/",
  timeout: 0
});

service.interceptors.request.use((config) => {
  if (!loading) {
    loading = Loading.service({
      lock: true,
      text: "加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
  }
  return config;
});

service.interceptors.response.use(
  (res) => {
    closeLoading();
    if (res.status === 200) {
      return res.data;
    } else {
      return Promise.reject(res);
    }
  },
  (err) => {
    closeLoading();
    return Promise.reject(err);
  }
);

Vue.prototype.$http = service;

export const $http = service as MAxiosInstance;
